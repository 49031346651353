import { Component, Input, OnInit } from '@angular/core';
import { ParticipantInfo, RaceShareResponse, RaceInfo } from 'src/app/components/interfaces/race-share.interface';

@Component({
  selector: 'app-event-details',
  templateUrl: './event-details.component.html',
  styleUrls: ['./event-details.component.scss']
})
export class EventDetailsComponent implements OnInit {
 @Input() eventDetails!: RaceShareResponse;
 @Input() raceInfo !: RaceInfo;
 experienceOwnerName!: string;
 participantInfo!: ParticipantInfo;
 profileInitials!: string;
 participantProfileImage: any;
 isBestTime: boolean = false;
 subType!: string;
 itemType!: string;
 raceName!: string;
 lapCount!: number;
 isDriftChallenge!: boolean;
 @Input() exercisename!:string;

  ngOnInit(): void {
   this.raceName = this.raceInfo.raceName ? this.raceInfo.raceName : '';
   this.lapCount =  this.raceInfo.maxValues.lapCount
   this.itemType = this.eventDetails.itemType;
   this.participantInfo = this.eventDetails.participantInfo;
   this.experienceOwnerName = this.participantInfo?.firstName +" "+ this.participantInfo?.lastName;
   this.profileInitials = this.participantInfo.firstName.charAt(0).toUpperCase() + this.participantInfo?.lastName.charAt(0).toUpperCase();
   this.participantProfileImage = this.participantInfo.profileImage;
   this.subType = this.eventDetails.subType;
   this.isBestTime = (this.eventDetails?.eventInfo?.exerciseInfo?.exerciseType?.includes('BESTTIME')  || this.eventDetails?.exerciseInfo?.exerciseType?.includes('BESTTIME'))?? false;
   this.isDriftChallenge = (this.eventDetails?.eventInfo?.exerciseInfo?.exerciseType?.includes('DRIFT')  || this.eventDetails?.exerciseInfo?.exerciseType?.includes('DRIFT')) ?? false;
}
}
