import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from '@angular/core';
import { DataService } from '../../services/data-services/data-service';
import { RaceShareResponse } from 'src/app/components/interfaces/race-share.interface';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { AwsAuthService } from 'src/app/services/aws-auth.service';
import { HttpErrorResponse } from '@angular/common/http';
import { TranslationService } from 'src/app/services/translation.service';
@Component({
  selector: 'app-race-share-main',
  templateUrl: './race-share-main.component.html',
  styleUrls: ['./race-share-main.component.scss'],
})
export class RaceShareMainComponent implements OnInit {
  isShowSpinner: boolean = false;
  isBackButtonVisible: boolean = false;
  raceSharePageInfo!: RaceShareResponse;
  isLeaderBoardVisible: boolean = false;
  isImpressionsVisible: boolean = false;
  id: string = '';
  selectedLanguage = 'en-GB';
  translationData = [];
  totalPages = 0;
  public page: any = 0;
  isAccessDenied: boolean = false;
  accessTokenData = { access_token: '' };
  isNotFound = false;
  language = 'en-GB';
  isLoggedIn = false;
  isBestTimeChallenge: boolean = false;
  isDriftChallenge: boolean = false;
  isTrackError: boolean = false;
  trackGuid!: string;
  trackInfo: any;
  isSubType!: string;
  exercisename!:string;

  // @HostListener('window:scroll', ['$event'])
  // onScroll(_event?: Event): void {
  //   const footer = document.getElementById('footer-container');
  //   if (window.scrollY >= 320) {
  //     // this.isMinCardActive = true;
  //   } else {
  //     // this.isMinCardActive = false;
  //   }
  //   if (footer) {
  //     if (
  //       window.innerHeight + window.scrollY >=
  //       document.body.scrollHeight - footer.offsetHeight
  //     ) {
  //       if (this.page < this.totalPages - 1) {
  //         // this.loadMoreEventsButton();
  //       }
  //     }
  //   }
  // }
  constructor(
    private dataService: DataService,
    private route: ActivatedRoute,
    private router: Router,
    private awsAuth: AwsAuthService,
    private translationService: TranslationService
  ) {}

  ngOnInit(): void {
    this.translationService.getTranslationDataAsObservable().subscribe(
      (translationData: any) => {
        this.translationData = translationData;
      }
    )
    this.route?.params.subscribe((params: any): void => {
      this.id = params.id;
    });
    let userLoggedIn = this.awsAuth.getUserLoginStatus();
    if (!userLoggedIn) {
      this.selectedLanguage = localStorage.getItem('language') || 'en-GB';
      localStorage.setItem('routeUrl', this.router.url);
      this.getRaceShareAccessToken();
    } else {
      this.getRaceDetails(this.id);
    }
  }


// Ayushi to check how to make this code reusable
  getRaceShareAccessToken() {
    this.awsAuth.currentSession()?.then(
      (_res) => {
        let accessToken = _res.getAccessToken();
        let jwt = accessToken.getJwtToken();
        let jwt_expires_in = accessToken.getExpiration();
        let refreshToken = _res.getRefreshToken();
        let jwtRefreshToken = refreshToken.getToken();
        let idToken = _res.getIdToken();
        let jwtIdToken = idToken.getJwtToken();
        let fullData = {
          id_token: jwtIdToken,
          access_token: jwt,
          refresh_token: jwtRefreshToken,
          expires_in: jwt_expires_in,
          token_type: 'Bearer',
        };
        this.awsAuth.setUserLoginStatus(true);
        this.awsAuth.setCurrentUserToken(fullData);
        this.accessTokenData = this.awsAuth.getCurrentUserToken();
        this.getRaceDetails(this.id);
      },
      (err: HttpErrorResponse) => {
        //
        this.awsAuth.signIn();
      }
    );
  }

  getRaceDetails(id: string): void {
    this.dataService.getRaceShareInfo(id).subscribe(
      (data: any) => {
        this.raceSharePageInfo = data;
        this.isSubType = data.subType;
        this.exercisename = data.exerciseInfo.exerciseName 
        this.trackGuid = this.raceSharePageInfo.trackInfo.trackId;
        this.eventTrackDetailsData(this.trackGuid);
        this.isBestTimeChallenge = (data.eventInfo?.exerciseInfo?.exerciseType?.includes('BESTTIME')  || this.raceSharePageInfo?.exerciseInfo?.exerciseType?.includes('BESTTIME'))?? false;
        this.isDriftChallenge = (this.raceSharePageInfo?.eventInfo?.exerciseInfo?.exerciseType?.includes('DRIFT')  || this.raceSharePageInfo?.exerciseInfo?.exerciseType?.includes('DRIFT')) ?? false;

      },
      (error: HttpErrorResponse) => {
        this.isShowSpinner = true;
        if (error.error['error-code'] === '8010') {
          // 403 error code : Provided ciamId is either invalid or doesn't have enough access!
          this.isAccessDenied = true;
        } else if (error.error['error-code'] === '9006') {
          // redirect to 404 : url is invalid
          this.router.navigateByUrl('/' + this.selectedLanguage + '/404');
        } else {
          this.router.navigateByUrl('/' + this.selectedLanguage + '/404');
          console.log('Error while fetching participant info');
        }
      }
    );
  }

  eventTrackDetailsData(trackGuid: string){
    this.dataService.getTrack(trackGuid).subscribe((data: any)=>{
      this.trackInfo = data;
      this.isShowSpinner = true;
    },
  (error: HttpErrorResponse)=>{
    this.isTrackError =  true;
    this.isShowSpinner = true;
  });
  }
}
